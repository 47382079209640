import React from "react";

export const Index = () => {
  return (
    <video autoPlay playsInline muted loop id="vid" height="100%" width="100%">
      <source
        src="https://res.cloudinary.com/du4aukj6a/video/upload/v1599210195/dearProgress_t3kb8j-ac_none_abqgqg.mp4"
        type="video/mp4"
      />
    </video>
  );
};
export default Index;
